<template>
  <IonPage>
    <ion-header :translucent="true">
      <ion-toolbar :color="store.url.includes('kievit') ? 'light' : 'primary'">
        <ion-buttons slot="start">
          <ion-back-button
              :style="store.url.includes('kievit') ? 'color:var(--ion-color-dark)' : 'color:var(--ion-color-light)'"
              default-href="/home"
          ></ion-back-button>
          <!--          <img-->
          <!--            @click="() => ionRouter.navigate('/home', 'back', 'push')"-->
          <!--            class="header-logo"-->
          <!--            alt="no-image"-->
          <!--            src="../assets/logo.png"-->
          <!--          />-->
          <img
              @click="() => raam.href = raam.origin"
              class="header-logo"
              alt="no-image"
              :src="store.app_logo"
          />
        </ion-buttons>

        <ion-buttons slot="end">
          <ion-button :style="store.url.includes('kievit') ? 'color:var(--ion-color-dark)' : 'color:var(--ion-color-light)'">

            <i slot="icon-only" style="font-size: 24px" class="fa-regular fa-question-circle"></i>
          </ion-button>
          <ion-button :style="store.url.includes('kievit') ? 'color:var(--ion-color-dark)' : 'color:var(--ion-color-light)'">
            <ion-menu-toggle
                style="
                z-index: 200;
                position: absolute;
                width: 100px;
                height: 100%;
              "
            ></ion-menu-toggle>
            <i style="font-size: 24px" class="fa-regular fa-bars"></i>
            <ion-badge
                color="secondary"
                style="
                display: none;
                z-index: 100;
                position: fixed;
                right: 0;
                top: 0;
              "
                :style="
                !store.logged_in_user ? 'display: block' : 'display: none'
              "
            >
              <i class="fa-regular fa-user-xmark"></i
              ></ion-badge>
            <ion-menu-toggle
                style="position: absolute; width: 100%; height: 100%"
            ></ion-menu-toggle>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <IonContent :color="store.url.includes('kievit') ? 'white' : 'light'" style="display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start; padding: 10px;">
      <!-- Left side: Marker Placement Controls -->
      <div>
        <button @click="startScan">Start Foot Scan</button>
        <button @click="stopScan">Stop Scan</button>
      </div>
    </IonContent>
  </IonPage>
</template>

<script setup>
import {
  IonHeader,
  IonContent,
  IonPage,
  IonToolbar,
  IonButton,
  IonButtons, IonMenuToggle, IonBadge, IonBackButton
} from '@ionic/vue';

import {FeetScanner} from 'feet-scanner'

console.log(FeetScanner)

import {useGlobalStore} from "../store/global";

const store = useGlobalStore();

const raam = window.location;

async function startScan() {
  try {
    await FeetScanner.startScanning();
    console.log('Foot scan started');
  } catch (error) {
    console.error( error);
  }
}
async function stopScan() {
  try {
    await FeetScanner.stopScanning();
    console.log('Foot scan stopped');
  } catch (error) {
    console.error( error);
  }
}

</script>

<style scoped>

</style>