<template>
  <ion-page>
    <ion-menu
      v-if="router.options.history.state.back"
      style="--width: 28em"
      side="start"
      content-id="men"
      menu-id="men"
      class="my-custom-menu"
    >
      <ion-header :translucent="true">
        <ion-toolbar color="primary">
          <ion-buttons slot="end">
            <ion-button @click="menuController.close('men')">Close</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-content style="width: 28em; background: #f7f7f7" color="light">
        <div style="width: 100%; padding: 12px">
          <ModelPageFilter
            v-if="route.path.includes('osa')"
            propName="priceLevel"
            :model="selectedPriceLevel"
            title="Prijs niveau"
            :items="priceLevels"
          />
          <ModelPageFilter
            v-if="route.path.includes('osa')"
            propName="priceLevel"
            :model="selectedHealthcareProvider"
            title="Zorgverzekeraars"
            :items="healthcareProviders"
          />
          <ModelPageFilter
            v-if="route.path.includes('osa')"
            propName="priceLevel"
            :model="selectedSupplier"
            title="Leveranciers"
            :items="suppliers"
          />

          <ion-card style="padding: 16px">
            <ion-list v-if="route.path.includes('osb')">
              <ion-list-header>
                <ion-label>Technische filters</ion-label>
              </ion-list-header>
              <ion-item :detail="false" lines="inset">
                <!--                <ion-label>Klittenband optioneel</ion-label>-->
                <ion-toggle
                    aria-label="Versnelde afwikkeling"
                    @ionChange="
                    filterSpecs('5e70ff5b-9b48-448b-9948-152d686a76ae')
                  "
                    value="5e70ff5b-9b48-448b-9948-152d686a76ae"
                    :checked="false"
                >Versnelde afwikkeling
                </ion-toggle>
              </ion-item>
              <ion-item :detail="false" lines="inset">
                <!--                <ion-label>Klittenband optioneel</ion-label>-->
                <ion-toggle
                  aria-label="Klittenband optioneel"
                  @ionChange="
                    store.filterModels('specs','6849475f-b7f0-46b5-a9ec-6e5b8f790fe6')
                  "
                  value="6849475f-b7f0-46b5-a9ec-6e5b8f790fe6"
                  :checked="false"
                  >Klittenband tegen meerprijs
                </ion-toggle>
              </ion-item>
              <ion-item :detail="false" lines="inset">
                <!--                <ion-label>Stretchleder optioneel</ion-label>-->
                <ion-toggle
                  aria-label="Stretchleder optioneel"
                  @ionChange="
                    filterSpecs('38b996ba-d46c-40ba-8099-8140c501a3ec')
                  "
                  value="38b996ba-d46c-40ba-8099-8140c501a3ec"
                  :checked="false"
                  >Stretchleder optioneel
                </ion-toggle>
              </ion-item>
              <ion-item :detail="false" lines="inset">
                <!--                <ion-label>Diabetische voering optioneel</ion-label>-->
                <ion-toggle
                  aria-label="Diabetische voering optioneel"
                  @ionChange="
                    filterSpecs('6b5f1b8c-07de-4eaa-ae80-c2478bdd3f01')
                  "
                  value="6b5f1b8c-07de-4eaa-ae80-c2478bdd3f01"
                  :checked="false"
                  >Diabetische voering optioneel
                </ion-toggle>
              </ion-item>
              <ion-item :detail="false" lines="inset">
                <!--                <ion-label>Leestmodificaties optioneel</ion-label>-->
                <ion-toggle
                  aria-label="Leestmodificaties optioneel"
                  @ionChange="
                    filterSpecs('464156af-b72d-4888-91ba-e4d0e2c4f88d')
                  "
                  value="464156af-b72d-4888-91ba-e4d0e2c4f88d"
                  :checked="false"
                  >Leestmodificaties optioneel
                </ion-toggle>
              </ion-item>
              <ion-item :detail="false" lines="inset">
                <!--                <ion-label>Schacht/onderwerk aanpassingen optioneel</ion-label>-->
                <ion-toggle
                  aria-label="Schacht onderwerk aanpassingen optioneel"
                  @ionChange="
                    filterSpecs('38b996ba-d46c-40ba-8099-8140c501a3ec')
                  "
                  value="38b996ba-d46c-40ba-8099-8140c501a3ec"
                  :checked="false"
                  >Schacht/onderwerk aanpassingen optioneel
                </ion-toggle>
              </ion-item>
            </ion-list>

            <ion-list v-if="route.path.includes('osb')">
              <ion-list-header style="opacity: 0.95">
                <ion-label>Merken</ion-label>
              </ion-list-header>
              <ion-item
                v-for="(item, key) in brands"
                :key="key"
                lines="full"
                :detail="false"
              >
                <ion-checkbox
                    label-placement="end"
                    slot="start"
                    justify="start"
                  :aria-label="item.label"
                  @update:modelValue="store.filterModels('brand', item.value)"
                  :checked="false"
                  color="secondary"
                  :value="item.value"
                  >{{ item.label }}
                </ion-checkbox>
                <!--                <ion-label>{{ item.label }}</ion-label>-->
              </ion-item>
            </ion-list>

            <ion-list
              v-if="route.path.includes('osb') && store.category !== 'k'"
              style="margin-top: 16px"
            >
              <ion-item :detail="false" lines="inset">
                <!--                <ion-label>Prijsfilter</ion-label>-->
                <ion-toggle
                  aria-label="Prijsfilter"
                  @ionChange="setPricefilter"
                  :value="store.priceFilter"
                  :checked="store.priceFilter"
                  >Prijsfilter
                </ion-toggle>
              </ion-item>
            </ion-list>
          </ion-card>
        </div>
      </ion-content>
    </ion-menu>

    <ion-header condense="fade" :translucent="true">
      <ion-toolbar :color="store.url.includes('kievit') ? 'light' : 'primary'">

        <ion-buttons slot="start">
          <ion-back-button v-if="!store.url.includes('wittepoel')"
                           :style="store.url.includes('kievit') ? 'color:var(--ion-color-dark)' : 'color:var(--ion-color-light)'"
                           default-href="/home"
          ></ion-back-button>
          <ion-back-button v-else
                           :style="'color:var(--ion-color-secondary)'"
                           default-href="/home"
          ></ion-back-button>

<!--          <ion-button shape="round"-->
<!--            v-if="!router.options.history.state.back"-->
<!--            @click="-->
<!--              () =>-->
<!--                router.options.history.state.back-->
<!--                  ? ionRouter.navigate('/home', 'back', 'push')-->
<!--                  : (raam.href = store.url)-->
<!--            "-->
<!--          >-->
<!--            <i v-if="!store.url.includes('wittepoel')"-->
<!--              class="fas fa-arrow-left"-->
<!--              style="font-size: 1.5em"-->
<!--              :style="store.url.includes('kievit') ? 'color:var(&#45;&#45;ion-color-dark)' : 'color:var(&#45;&#45;ion-color-dark)'"-->
<!--            ></i>-->
<!--            <i v-else-->
<!--                class="fas fa-arrow-left"-->
<!--                style="font-size: 1.5em"-->
<!--               :style="'color:var(&#45;&#45;ion-color-secondary)'"-->
<!--            ></i>-->
<!--          </ion-button>-->
          <!--          <img-->
          <!--            tappable-->
          <!--            @click="-->
          <!--              () =>-->
          <!--                router.options.history.state.back-->
          <!--                  ? ionRouter.navigate('/home', 'back', 'push')-->
          <!--                  : (raam.href = 'https://footcare.nl')-->
          <!--            "-->
          <!--            class="header-logo"-->
          <!--            alt="no-image"-->
          <!--            src="../assets/logo.png"-->
          <!--          />-->
          <img
            tappable
            @click="
              () =>
                router.options.history.state.back
                  ? (raam.href = raam.origin)
                  : (raam.href = store.url)
            "
            class="header-logo"
            alt="no-image"
            :src="store.app_logo"
          />
        </ion-buttons>


        <ion-buttons slot="end">
          <ion-button v-if="!store.url.includes('wittepoel')" shape="round" :style="store.url.includes('kievit') ? 'color:var(--ion-color-dark)' : 'color:var(--ion-color-light)'" @click="openTheModal">

            <i slot="icon-only" style="font-size:28px" class="fa-regular fa-question-circle"></i>
          </ion-button>
          <ion-button v-else shape="round" :style="'color:var(--ion-color-secondary)'" @click="openTheModal">

            <i slot="icon-only" style="font-size:28px" class="fa-regular fa-question-circle"></i>
          </ion-button>

          <ion-searchbar
            v-if="innerWidth > 450"
            style="margin-right: 2px; padding: 5px; align-self: center"
            inputmode="search"
            debounce="1500"
            @ionInput="searchModels($event)"
          />

          <ion-button shape="round" @click="showFavorites()" :style="store.url.includes('kievit') ? 'color:var(--ion-color-dark)' : 'color:var(--ion-color-light)'">
            <div slot="icon-only">
              <i style="font-size:28px" class="fa-regular fa-heart"></i>
            </div>
            <ion-badge
                style="
                  position: absolute;
                  top: -5px;
                  right: -5px;
                  margin-right: -3px;
                "
                v-if="favorites.length > 0"
                color="secondary"
            >
              {{ favorites.length }}
            </ion-badge>
          </ion-button>

          <ion-button shape="round" @click="setAllFavorite()" :style="store.url.includes('kievit') ? 'color:var(--ion-color-dark)' : 'color:var(--ion-color-light)'">
            <q-tooltip max-width="50%" style="font-size: 16px">
              (De)Selecteer alle modellen als favoriet.
            </q-tooltip>
            <div slot="icon-only">
              <i style="font-size: 28px" class="fa-regular fa-heart"></i>
              <ion-badge
                  style="
                  position: absolute;
                  top: -4px;
                  right: -7px;
background:transparent;
                "
                  :style="store.url.includes('kievit') ? 'color:var(--ion-color-dark)' : 'color:var(--ion-color-light)'">
                <i style="font-size: 18px" class="fa-solid fa-square-plus"></i>
              </ion-badge>
            </div>

          </ion-button>

          <ion-button shape="round"
            v-if="router.options.history.state.back"
            :style="store.url.includes('kievit') ? 'color:var(--ion-color-dark)' : 'color:var(--ion-color-light)'"
          >
            <div slot="icon-only">
              <ion-menu-toggle
                menu="men"
                style="position: absolute; width: 100%; height: 100%"
              ></ion-menu-toggle>
              <i style="font-size: 28px" class="fa-regular fa-cog"></i>
            </div>
          </ion-button>
          <ion-button shape="round"
            v-if="router.options.history.state.back"
            :style="store.url.includes('kievit') ? 'color:var(--ion-color-dark)' : 'color:var(--ion-color-light)'"
          >
            <div slot="icon-only">
              <ion-menu-toggle
                style="position: absolute; width: 100%; height: 100%"
              ></ion-menu-toggle>
              <i style="font-size: 28px" class="fa-regular fa-bars"></i>
              <ion-badge
                color="secondary"
                style="
                  display: none;
                  z-index: 100;
                  position: fixed;
                  right: 0;
                  top: 0;
                "
                :style="
                  !store.logged_in_user ? 'display: block' : 'display: none'
                "
              >
                <i class="fa-regular fa-user-xmark"></i
              ></ion-badge>
            </div>
          </ion-button>

        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" id="men" :color="store.url.includes('kievit') ? 'white' : 'light'">
      <div slot="fixed" class="row" style="width: 100%; height: 100%">
        <div class="col-xs-0 col-sm-4 col-md-3 col-lg-2">
          <q-scroll-area
            style="height: 100%; margin: 0"
            :thumb-style="thumbStyle"
            :bar-style="barStyle"
          >

            <ModelPageFilter
                propName="closings"
                title="Sluiting"
                :items="closings"
                :model="selectedClosings"
            />

            <ModelPageFilter
                v-if="route.path.includes('osb')"
                propName="colors"
                title="Kleurtinten"
                :model="selectedColors"
                :items="colors"
            />

            <ModelPageFilter
                propName="height"
                title="Schoen hoogte"
                :items="heights"
                :model="selectedHeight"
            />

            <ModelPageFilter
              propName="styles"
              title="Styles"
              :items="styles"
              :model="selectedStyles"
            />


          </q-scroll-area>
        </div>

        <div class="col-xs-12 col-sm-8 col-md-9 col-lg-10">
          <q-scroll-area
              ref="scroller"
              id="scroller"
              class="scroller"
              :visible="true"
            style="height: 100%; margin: 0;width:100%"
            :thumb-style="thumbStyle"
            :bar-style="barStyle"
          >
            <div
              v-if="!store.imageLoadingState"
              class="row"
              style="width: 100%; height: 100%"
            >
              <ModelCardSkeleton v-for="i in 15" :key="'skel' + i" />
            </div>
            <div v-else class="row" style="width: 100%; height: 100%">
              <q-infinite-scroll
                @load="onLoad"
                class="row"
                :disable="store.scrollState"
                :offset="1000"
                :style="
                  store.imageLoadingState
                    ? 'position:absolute;'
                    : 'position:relative;'
                "
                style="top: 0; height: 100%; width: 100%"
              >
                <ModelCard
                  v-for="item in store.models"
                  :key="'card' + item?.modelID"
                  :item="item"
                  :setFavorite="setFavorite"
                  :dbModel="dbModel"
                />

                <template v-slot:loading>
                  <!--              <div class="col-12">-->
                  <!--                <div class="row">-->
                  <!--                  <ModelCardSkeleton/>-->
                  <!--                  <ModelCardSkeleton/>-->
                  <!--                  <ModelCardSkeleton/>-->
                  <!--                </div>-->
                  <!--              </div>-->
                  <div class="col-12">
                    <div class="row justify-center">
                      <q-spinner-dots color="primary" size="50px" />
                    </div>
                  </div>
                </template>
              </q-infinite-scroll>
            </div>
          </q-scroll-area>
        </div>
      </div>

      <!--    <ion-content :fullscreen="true" id="men" style="width: 100%;height: 100%">-->

      <!--      <ion-grid slot="fixed" style="height:100%;width:100%">-->

      <!--        <ion-row style="height:100%">-->
      <!--          &lt;!&ndash;  the side menu  &ndash;&gt;-->
      <!--          <ion-col style="height:100%" class="ion-hide-sm-down" size-sm="3" size-lg="3">-->

      <!--            <ion-content style="&#45;&#45;padding-start: 0;&#45;&#45;padding-end: 0">-->
      <!--              <ion-grid style="height:100%;width:100%">-->
      <!--                <ion-row style="height: 100%;">-->
      <!--                  <ion-col size="12">-->

      <!--                    <ModelPageFilter propName="styles"-->
      <!--                                     title="Styles"-->
      <!--                                     :items="styles"-->
      <!--                                     :model="selectedStyles"/>-->

      <!--                    <ModelPageFilter propName="height"-->
      <!--                                     title="Schoen hoogte"-->
      <!--                                     :items="heights"-->
      <!--                                     :model="selectedHeight"/>-->

      <!--                    <ModelPageFilter propName="closings"-->
      <!--                                     title="Sluiting"-->
      <!--                                     :items="closings"-->
      <!--                                     :model="selectedClosings"/>-->

      <!--                    <ModelPageFilter v-if="route.path.includes('osb')" propName="colors" title="Kleurtinten"-->
      <!--                                     :model="selectedColors"-->
      <!--                                     :items="colors"/>-->

      <!--                  </ion-col>-->
      <!--                </ion-row>-->
      <!--              </ion-grid>-->
      <!--            </ion-content>-->
      <!--          </ion-col>-->

      <!--          <ion-col size-xs="12"-->
      <!--                   size-sm="9"-->
      <!--                   size-lg="9"-->
      <!--                   style="height:100%;">-->
      <!--            <ion-content style="&#45;&#45;padding-start: 0;&#45;&#45;padding-end: 0;height: 100%;width: 100%">-->

      <!--              <ion-grid style="&#45;&#45;ion-grid-width:100%;height:auto;margin-bottom:200px">-->

      <!--                <ion-row :style="store.imageLoadingState ? 'z-index:0;opacity:0' : 'z-index:2;opacity:1'"-->
      <!--                         style="width:100%;height:100%;transition: opacity 0.5s;">-->

      <!--                  <ion-col v-for="i in 20" :key="'skel' + i"-->
      <!--                           size-xs="12"-->
      <!--                           size-sm="6"-->
      <!--                           size-md="6"-->
      <!--                           size-lg="4"-->
      <!--                           size-xl="4">-->

      <!--                    <ModelCardSkeleton/>-->

      <!--                  </ion-col>-->

      <!--                </ion-row>-->

      <!--                <ion-row-->
      <!--                    :style="store.imageLoadingState ? 'position:absolute;z-index:2;opacity:1':'position:relative;z-index:0;opacity:0'"-->
      <!--                    style="transition: opacity 0.5s;top:0;height:100%;width:100%;">-->

      <!--                  <ModelCard v-for="item in store.models" :key="'card' + item?.modelID"-->
      <!--                             :item="item"-->
      <!--                             :setFavorite="setFavorite"-->
      <!--                             :isOpenRef="isOpenRef"-->
      <!--                             :setOpen="setOpen"-->
      <!--                             :dbModel="dbModel"/>-->

      <!--                </ion-row>-->

      <!--              </ion-grid>-->
      <!--              <ion-infinite-scroll-->
      <!--                  position="bottom"-->
      <!--                  @ionInfinite="loadData($event)"-->
      <!--                  id="infinite-scroll"-->
      <!--                  :disabled="isDisabled"-->
      <!--                  style="height: 100px;z-index: 5000;width:100%"-->
      <!--              >-->
      <!--                <ion-infinite-scroll-content-->
      <!--                    loading-text="Loading more data...">-->
      <!--                </ion-infinite-scroll-content>-->
      <!--              </ion-infinite-scroll>-->
      <!--            </ion-content>-->

      <!--          </ion-col>-->

      <!--        </ion-row>-->

      <!--        <ModelModal v-if="store.isOpen"-->
      <!--                    :innerWidth="innerWidth"-->
      <!--                    :isOpenRef="isOpenRef"-->
      <!--                    :setOpen="setOpen"-->
      <!--                    :setFavorite="setFavorite"/>-->

      <!--      </ion-grid>-->
      <ModelModal
        v-if="store.isOpen"
        :innerWidth="innerWidth"
        :setFavorite="setFavorite"
      />
      <!--    </ion-content>-->
    </ion-content>
  </ion-page>
</template>

<script setup>
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonCard,
  IonToggle,
  IonToolbar,
  menuController,
  useIonRouter,
  alertController,
  loadingController,
  onIonViewDidEnter,
} from "@ionic/vue";

import {QTooltip, useQuasar} from "quasar";

import ModelPageFilter from "@/components/ModelpageFilter";
import ModelCard from "@/components/ModelCard";
import ModelCardSkeleton from "@/components/ModelCardSkeleton";
import ModelModal from "@/components/ModelModal";

import {onMounted, ref} from "vue";

import { useRoute, useRouter } from "vue-router";

import { DataStore, Predicates, SortDirection } from "aws-amplify/datastore";
import { downloadData } from 'aws-amplify/storage';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

import { useGlobalStore } from "@/store/global";

import {
  DevFittrClosing,
  DevFittrColor,
  DevFittrHeight,
  DevFittrPriceLimit,
  DevFittrStyle,
  DevFootcareClosing,
  DevFootcareHealthcareProvider,
  DevFootcareModel,
  DevFootcarePriceLevel,
  DevFootcareStyle,
  DevFootcareSupplier,
} from "@/models";

import {
  getDevModelCollection,
  listDevFootcareModels,
} from "@/graphql/queries";

import * as queries from "../graphql/queries";

import EventBus from '../Eventbus.js';
const helpContent = ref(`
    <h6>Modellen Bladeren en Selecteren</h6>

<ol>
    <li><strong>Filteren:</strong> Selecteer uit de filteropties in de linker kolom om modellen te filteren op specifieke voorkeuren.</li>
    <li><strong>Technische Filters en Prijsfilter:</strong> Open de technische filters door op het tandwiel-icoon te klikken. Hier kunt u specifieke functies selecteren zoals klittenband, stretchleer of diabetische voering en schoenen die opties bieden voor leestmodificaties en aanpassingen aan de schacht of het onderwerk.
        <ul>
            <li>Naast de technische filters vindt u ook een prijsfilter switch. Standaard worden schoenmodellen getoond die onder een ingesteld prijsplafond vallen. Door de prijsfilter switch om te zetten, worden ook duurdere modellen getoond. Let op, modellen buiten het plafond worden vaak niet volledig door zorgverzekeraars gedekt. Een aanvullende motivatie kan nodig zijn binnen uw organisatie bij het kiezen van deze modellen.</li>
        </ul>
    </li>
    <li><strong>Modeldetails en bestel- of deelopties:</strong> Om een bestelling te plaatsen, één of meerdere modellen te delen of om aanvullende modeldetails weer te geven, klikt u op de foto van een model om de modeldetail popup te openen. In deze popup vindt u aanvullende informatie over het model, inclusief beschikbare lengte- en wijdtematen.
        <ul>
            <li>Linksboven vindt u de deelopties om modellen te delen met cliënten of collega’s.</li>
            <li>Gebruik de ‘Bestellen’ knop binnen de popup om direct een bestelling te plaatsen.</li>
        </ul>
    </li>
</ol>

`);

function openTheModal() {
  store.helpContent = helpContent.value;
  // emit the 'openHelp' event
  EventBus.emit('openHelpModal');
}

const models = ref([]);
const route = useRoute();
const $q = useQuasar();
const router = useRouter();
const ionRouter = useIonRouter();
const store = useGlobalStore();
store.priceFilter = true;

store.filters = {
  styles: [],
  height: [],
  closings: [],
  colors: [],
  priceLevel: [],
  healthcareProviders: [],
  suppliers: [],
  brand: [],
  specs: [],
};

store.selectedFilters = [];

if (router.options.history.state.back) {
  localStorage.removeItem("favorites");
}

const favorites = ref([]);
const styles = ref([]);
const closings = ref([]);
const colors = ref([]);
const heights = ref([]);
const insteps = ref([]);
const priceLevels = ref([]);
const healthcareProviders = ref([]);
const suppliers = ref([]);
const brands = ref([]);

const searchRef = ref("");
const search = ref("");

const innerWidth = ref(0);

const selectedSpecs = ref([]);
const selectedStyles = ref([]);
const selectedHeight = ref([]);
const selectedClosings = ref([]);
const selectedColors = ref([]);
const selectedPriceLevel = ref([]);
const selectedHealthcareProvider = ref([]);
const selectedSupplier = ref([]);

let dbModel;
let category;
let filter1;
let filter2;

const showLoading = async () => {
  const loading = await loadingController.create({
    message: "Een moment geduld alstublieft, filter wordt toegepast...",
  });

  await loading.present();
};

const hideLoading = async () => {
  await loadingController.dismiss();
};

async function fetchData() {
  store.models = [];
  store.initialisedModels = [];
  store.cheapModels = [];
  store.expensiveModels = [];
  store.imageLoadingState = false;
  store.filterLoadingState = false;

  let list = ref([]);
  if (router.options.history.state.back) {
    if (router.options.history.state.back !== "/home") {
      list.value = store.modelCollection;
      await loadFilters();
      await finishLoading(list);
      console.log("local data loaded");
    } else {
      list.value = store.modelCollection;
      loadFilters();
      finishLoading(list);
      console.log("store data loaded");
    }
  } else {
    let temp;

    if (JSON.parse(route.params.collectionId).collectionId) {
      temp = await client.graphql({
        query: getDevModelCollection,
        variables: { id: JSON.parse(route.params.collectionId).collectionId },
      });
      try {
        const downloadResult = await downloadData({ key: temp.data.getDevModelCollection.models }).result;
        const text = await downloadResult.body.text();
        list.value = JSON.parse(text);
        // Alternatively, you can use `downloadResult.body.blob()`
        // or `downloadResult.body.json()` get read body in Blob or JSON format.
        //console.log('Succeed: ', text);
      } catch (error) {
        console.log('Error : ', error);
      }

      await loadFilters();
      await finishLoading(list);
    } else {
      let filter = {
        category: {
          contains: JSON.parse(route.params.collectionId).category, // filter priority = 1
        },
      };
      const newList = ref([]);
      temp = await client.graphql({
        query: listDevFootcareModels,
        variables: { limit: 500, filter: filter },
      });
      temp = temp.data.listDevFootcareModels.items;

      temp.sort((firstEl, secondEl) => {
        //console.log(JSON.parse(firstEl.price))
        let x = firstEl.modelnumber.toLowerCase();
        let y = secondEl.modelnumber.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      for (let i = 0; i < temp.length; i++) {
        let mod = JSON.parse(JSON.stringify(temp[i]));
        if (!mod._deleted) {
          mod.modelID = temp[i].id;
          mod.attributes = JSON.parse(temp[i].attributes);
          newList.value.push(mod);
        }
      }
      list.value = newList.value;
      await loadFilters();
      await finishLoading(list);
    }
    console.log("online data loaded");
  }
}

const finishLoading = async (list) => {

  if (route.path.includes("osb") && store.category !== 'k') {
    const newList = ref([]);

    for (let i = 0; i < list.value.length; i++) {
      let model = JSON.parse(JSON.stringify(list.value[i]));
      if (model.prices?.price) {
        if (model.prices?.price === "" || model.prices?.price === "€0" ) {
          model.price = 165;
        }
        else {
          model.price = parseFloat(model.prices.price.replace("€",""));
        }
      }
      else if (model.prices?.maten) {
        for (const property in model.prices.maten) {
          if (property.toString().includes("-") === true) {
            let sizerange = property.toString().split("-");
            try {
              if (model.size_left.toString() >= sizerange[0].toString() && model.size_left.toString() <= sizerange[1].toString()) {
                model.price = parseFloat(model.prices.maten[property.toString()].replace("€",""));
              }
            }
            catch (e) {
              if (parseFloat(model.prices.maten[property.toString()]) !== "") {
                try {
                  model.price = parseFloat(model.prices.maten[0].replace("€",""));
                }
                catch (e) {
                  try {
                    model.price = parseFloat(model.prices.maten[1].replace("€",""));
                  }
                  catch (e) {
                    try {
                      model.price = parseFloat(model.prices.maten[2].replace("€",""));
                    }
                    catch (e) {
                      try {
                        model.price = parseFloat(model.prices.maten[3].replace("€",""));
                      }
                      catch (e) {
                        try {
                          model.price = parseFloat(model.prices.maten[4].replace("€",""));
                        }
                        catch (e) {
                          try {
                            model.price = parseFloat(model.prices.maten[5].replace("€",""));
                          }
                          catch (e) {

                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          else {
            try {
              if (model.size_left.toString() === property.toString()) {
                model.price = parseFloat(model.prices.maten[property.toString()].replace("€",""));
              }
            }
            catch (e) {
              if (parseFloat(model.prices.maten[property.toString()]) !== "") {
                model.price = parseFloat(model.prices.maten[property.toString()].replace("€",""));
              }
            }

          }
        }
      }
      else if (model.prices?.lasttypes) {
        for (const property in model.prices.lasttypes) {
          try {
            if (model.lasttype_left.toString() === property.toString()) {
              model.price = parseFloat(model.prices.lasttypes[property.toString()].replace("€",""));
            }
          }
          catch (e) {
            if (parseFloat(model.prices.lasttypes[property.toString()]) !== "") {
              model.price = parseFloat(model.prices.lasttypes[property.toString()].replace("€",""));
            }
          }
        }
      }
      /* else if (model.prices?.lasttypes) {
        prices = [];
        for (const property in model.prices.lasttypes) {
          if (model.prices.lasttypes[property] !== "") {
            const price = property + ": " + model.prices.lasttypes[property].replaceAll(" ","");
            prices.push(price);
            prices.sort();
          }
        }
      } */

      /* try {
        model.price = parseFloat(model.prices.prices.replace("€",""));
      }
      catch (e) {
        model.price = parseFloat(model.prices.price.replace("€",""));
      } */
      //console.log(model)
      model.modelID = model.id;
      newList.value.push(model);
    }
    store.priceFilter = true;
    store.expensiveModels = newList.value;

    if (store.logged_in_user) {
      const priceLimitObj = await store.getPriceLimit(store.logged_in_user["custom:organisation"]);
      store.priceLimit = parseInt(priceLimitObj) + 1;
    } else {
      if (store.url.includes('kievit')) {
        const priceLimitObj = await store.getPriceLimit("Kievit");
        store.priceLimit = parseInt(priceLimitObj) + 1;
      }
      else if (store.url.includes('penders')) {
        const priceLimitObj = await store.getPriceLimit("Penders");
        store.priceLimit = parseInt(priceLimitObj) + 1;
      }
      else if (store.url.includes('footcare')) {
        const priceLimitObj = await store.getPriceLimit("Footcare");
        store.priceLimit = parseInt(priceLimitObj) + 1;
      }
      else if (store.url.includes('fittr')) {
        const priceLimitObj = await store.getPriceLimit("Fittr");
        store.priceLimit = parseInt(priceLimitObj) + 1;
      }
      else {
        const priceLimitObj = await store.getPriceLimit("Fittr");
        store.priceLimit = parseInt(priceLimitObj) + 1;
      }
    }
    store.cheapModels = [];
    store.cheapModels = newList.value.filter(
      (model) =>
        parseInt(model.price) < parseInt(store.priceLimit)
    );

    store.cheapModels.sort((a, b) => a.price - b.price);
    store.expensiveModels.sort((a, b) => a.price - b.price);

    store.initialisedModels =
      store.priceFilter === true ? store.cheapModels : store.expensiveModels;

    if (store.cheapModels.length === 0) {
      const alert = await alertController.create({
        header: "Geen resultaten",
        message:
          "Er zijn voor uw schoenselectie helaas geen modellen beschikbaar die binnen de reguliere dekking van zorgverzekeraars vallen." +
          "Wilt u de prijsfilter uitschakelen? u kunt ook kiezen terug te gaan naar het leesten selectie schem.",
        buttons: [
          {
            text: "Terug",
            role: "cancel",
            cssClass: "secondary",
            id: "cancel-button",
            handler: () => {
              router.back();
            },
          },
          {
            text: "Ja",
            id: "confirm-button",
            handler: async () => {
              console.log("Confirm Okay");
              store.priceFilter = true;
              store.initialisedModels = store.expensiveModels;
              // store.priceFilter = false;
              // setPricefilter();
              await loadFilters();
              // await pushData();
              store.imageLoadingState = true;
              store.filterLoadingState = true;
            },
          },
        ],
      });
      await alert.present();
    } else {
      await loadFilters();
      store.imageLoadingState = true;
      store.filterLoadingState = true;
    }

    //store.initialisedModels.sort((a, b) => a.price - b.price);
//store.initialisedModels.sort();
    /* store.initialisedModels.sort((firstEl, secondEl) => {
      let x = firstEl.price;
      let y = secondEl.price;
      return x <= y ? -1 : x >= y ? 1 : 0;
    });
    console.log(store.initialisedModels) */
  } else {
    //console.log(list.value);
    const newL = ref([]);

    for (let i = 0; i < list.value.length; i++) {
      let model = JSON.parse(JSON.stringify(list.value[i]));
      model.modelID = model.id;
      //let mod;
      //if (!model.modelnumber) {
      //  //mod = await DataStore.query(DevFootcareModel, model.modelID);
      //  //mod = JSON.parse(JSON.stringify(mod));
      //  //mod.modelID = model.modelID;
      //  model.modelID = model.id;
      //}
      //else {
      //  mod = model;
      //}
      newL.value.push(model);
    }

     //newL.value.sort((firstEl, secondEl) => {
     //  return firstEl.modelnumber - secondEl.modelnumber
     //})

    console.log(newL.value)
    store.initialisedModels = newL.value;
    //store.initialisedModels = list.value;
    await loadFilters();
    await pushData();
    store.imageLoadingState = true;
    store.filterLoadingState = true;
  }
};
const searchModels = async (value) => {
  store.imageLoadingState = false;
  if (store.tempModels) {
    store.tempModels = store.initialisedModels;
  }
  store.tempModels = store.initialisedModels;
  const input = value.detail.value.toLowerCase();
  if (input !== "" && input !== null) {
    const modelsBySearch = new Set();

    store.initialisedModels.forEach((model) => {
      let search =
        model?.modelnumber?.toString() +
        model?.colornumber?.toString() +
        model?.colorname?.toString() +
        model?.modelname?.toString();
      search = search.toLowerCase();
      if (search.includes(input)) {
        modelsBySearch.add(model);
      }
    });

    const searchResult = [...modelsBySearch];
    if (searchResult.length > 0) {
      store.scrollState = true;
      searchResult
        .sort((firstEl, secondEl) => {
          return firstEl.modelnumber - secondEl.modelnumber;
        })
        .sort((firstEl, secondEl) => {
          return firstEl.colornumber - secondEl.colornumber;
        });
      store.models = [];
      store.models = searchResult;
    } else {
      $q.notify({
        color: "red-5",
        position: "center",
        textColor: "white",
        icon: "fas fa-triangle-exclamation",
        message: "Helaas, deze zoekopdracht geeft geen resultaten",
      });
      store.models = [];
      setTimeout(() => {
        value.target.value = "";
      }, 6000);
    }
  } else {
    items.value = [...store.tempModels];
    items.value
      .sort((firstEl, secondEl) => {
        return firstEl.modelnumber - secondEl.modelnumber;
      })
      .sort((firstEl, secondEl) => {
        return firstEl.colornumber - secondEl.colornumber;
      });
    store.models = items.value;
  }
  setTimeout(() => {
    store.imageLoadingState = true;
  }, 250);
};

const getFilterItems = async (table, param, quer) => {
  const result = [];
  let uniques = store.findUniqueModelValue(param);
  let temp;
  for (let i = 0; i < uniques.length; i++) {
    if (!router.options.history.state.back) {
      temp = await client.graphql({
        query: queries[quer],
        variables: { id: uniques[i] },
      });
      let tempItem = temp.data[quer];

      if (tempItem && param !== "instep_type") {
        let item = { id: tempItem?.id, item: tempItem?.item };
        result.push(item);
      } else if (param === "instep_type") {
        console.log(uniques[i]);
      }
    } else {
      let tempItem = await DataStore.query(table, uniques[i]);
      if (tempItem !== undefined && param !== "instep_type") {
        let item = { id: tempItem["id"], item: tempItem.item };
        result.push(item);
      } else if (param === "instep_type") {
        console.log(uniques[i]);
      }
    }
  }
  return result;
};

const loadFilters = async () => {
  if (route.path.includes("osa") && route.path.includes("models")) {
    styles.value = await getFilterItems(
      DevFootcareStyle,
      "styles",
      "getDevFootcareStyle"
    );
    heights.value = [
      { id: "laag", item: "laag", value: "laag" },
      { id: "hoog", item: "hoog", value: "hoog" },
    ];
    closings.value = await getFilterItems(
      DevFootcareClosing,
      "closings",
      "getDevFootcareClosing"
    );

    priceLevels.value = await DataStore.query(
      DevFootcarePriceLevel,
      Predicates.ALL,
      {
        sort: (s) => s.item(SortDirection.ASCENDING),
      }
    );

    suppliers.value = await DataStore.query(
      DevFootcareSupplier,
      Predicates.ALL,
      {
        sort: (s) => s.item(SortDirection.ASCENDING),
      }
    );

    healthcareProviders.value = await DataStore.query(
      DevFootcareHealthcareProvider,
      Predicates.ALL,
      {
        sort: (s) => s.item(SortDirection.ASCENDING),
      }
    );
  } else if (route.path.includes("osb") && route.path.includes("models")) {
    colors.value = await getFilterItems(
      DevFittrColor,
      "colors",
      "getDevFittrColor"
    );
    styles.value = await getFilterItems(
      DevFittrStyle,
      "styles",
      "getDevFittrStyle"
    );
    heights.value = await getFilterItems(
      DevFittrHeight,
      "height",
      "getDevFittrHeight"
    );
    closings.value = await getFilterItems(
      DevFittrClosing,
      "closings",
      "getDevFittrClosing"
    );

    brands.value = store.findUniqueModelValue("brand");
    const tempBrands = brands.value;
    brands.value = [];

    for (let i = 0; i < tempBrands.length; i++) {
      let brand = tempBrands[i];
      let obj = {
        value: brand,
        label: brand,
      };
      brands.value.push(obj);
    }
  }
};

const setActiveModel = (model) => {
  store.activeModel = model;
  store.model = model;
};

const setAllFavorite = () => {
  for (let i = 0; i < store.models.length; i++) {
    const id = store.models[i].id;
    setFavorite("null",id);
  }
};

const setFavorite = (event, id) => {
  const elem = document.querySelector("#favo_" + id);
  if (elem.className === "fal fa-heart") {
    elem.className = "fas fa-heart";
    favorites.value.push(id);
  } else {
    elem.className = "fal fa-heart";
    for (let index = 0; index < favorites.value.length; index++) {
      const favoId = favorites.value[index];
      if (favoId === id) {
        favorites.value.splice(index, 1);
      }
    }
  }
  if (router.options.history.state.back !== "/home") {
    localStorage.setItem("favorites", favorites.value);
  }
  if (store.favoritesOpen === true) {
    showFavorites();
  }
};

function showFavorites() {
  if (favorites.value.length > 0) {
    store.isOpen = true;
    store.activeModels = [];
    for (let i = 0; i < favorites.value.length; i++) {
      const favoID = favorites.value[i];
      let temp = store.initialisedModels.find(
        (model) => model.modelID === favoID
      );
      store.activeModels.push(temp);
    }
    setActiveModel(store.activeModels[0]);
    store.setOpen(true);
    store.favoritesOpen = true;
  } else {
    $q.notify({
      color: "red-5",
      position: "center",
      textColor: "white",
      icon: "fas fa-triangle-exclamation",
      message: "Er zijn nog geen favorieten geselecteerd!",
    });
    store.setOpen(false);
  }
}

function filterSpecs(spec) {
  let models;
  let newModels;

  if (selectedSpecs.value.indexOf(spec) === -1) {
    selectedSpecs.value.push(spec);
    models = store.models;
    newModels = models.filter((model) => model.attributes.specs.indexOf(spec) !== -1);
    store.models = newModels;
  } else {
    selectedSpecs.value.splice(selectedSpecs.value.indexOf(spec), 1);
    store.models = store.initialisedModels;
  }
}

const setPricefilter = async () => {
  await showLoading();
  store.models = [];
  max.value = 15;
  min.value = 0;
  items.value = [];
  if (store.priceFilter === true) {
    store.priceFilter = false;
    store.initialisedModels = store.expensiveModels;
    // store.models = store.expensiveModels
    pushData();
    loadFilters().then(() => {
      store.imageLoadingState = true;
      store.filterLoadingState = true;
    });
  } else {
    store.priceFilter = true;
    store.initialisedModels = store.cheapModels;
    // store.models = store.cheapModels;
    pushData();
    loadFilters().then(() => {
      store.imageLoadingState = true;
      store.filterLoadingState = true;
    });
  }
  await hideLoading();
};

if (route.path.includes("osa") && route.path.includes("models")) {
  dbModel = DevFootcareModel;
  filter1 = "modelnumber";
  filter2 = "colornumber";
} else if (route.path.includes("osb") && route.path.includes("models")) {
  dbModel = store.Models;
  filter1 = "brand";
  filter2 = "modelnumber";
}

innerWidth.value = window.innerWidth;

const min = ref(0);
const max = ref(15);
const items = ref([]);

const pushData = () => {
  if ((max.value + 55) < parseInt(store.initialisedModels.length)) {
    max.value = max.value + 55;
    //max.value = parseInt(store.initialisedModels.length);
    for (min.value; min.value < max.value; min.value++) {
      const model = store.initialisedModels[min.value];
      items.value.push(model);
    }
    store.scrollState = false;
    store.models = items.value;
    console.log("items pushed");
  } else if ((max.value + 55) >= parseInt(store.initialisedModels.length)) {
    max.value = parseInt(store.initialisedModels.length);
    //for (min.value; min.value < max.value; min.value++) {
    //  const model = store.initialisedModels[min.value];
    //  items.value.push(model);
    //}
    //store.models = items.value;
    store.models = store.initialisedModels;
    store.scrollState = true;
    console.log("last items pushed");
  }
  //store.models = store.initialisedModels;
  //store.scrollState = true;
};

const onLoad = async (index, done) => {
  pushData();
  if (
    router.options.history.state.back !== "/home" &&
    localStorage.getItem("favorites")
  ) {
    favorites.value = localStorage.getItem("favorites").split(",");
    for (let i = 0; i < favorites.value.length; i++) {
      const elem = document.querySelector("#favo_" + favorites.value[i]);
      if (elem) {
        elem.className = "fas fa-heart";
      }
    }
  }
  console.log("more items loaded");
  store.imageLoadingState = true;
  store.filterLoadingState = true;
  setTimeout(() => {
    done();
  }, 500);
  // done();
};

const thumbStyle = {
  right: "4px",
  borderRadius: "5px",
  backgroundColor: "orange",
  width: "5px",
  opacity: 0.75,
};

const barStyle = {
  right: "2px",
  borderRadius: "9px",
  backgroundColor: "orange",
  width: "9px",
  opacity: 0.2,
};

onIonViewDidEnter(async () => {
  if (router.options.history.state.back) {
    localStorage.removeItem("favorites");
  }
});

store.initAppGlobals();
store.scrollState = false;
fetchData();

const raam = window.location;

//const scroller = ref();
//const elems = document.getElementsByClassName('q-scrollarea__container');
//const elems = document.getElementsByClassName('q-scrollarea__container');

//onMounted(async () => {
//  console.log(scroller.value.$el.firstChild.classList);
//  scroller.value.$el.firstChild.classList.remove('hide-scrollbar')
//})
</script>

<style>
.q-infinite-scroll__loading {
  width: 100%;
}

.button-has-icon-only {
  --padding-top: unset;
  --padding-bottom: unset;
  --padding-end: unset;
  --padding-start: unset;
  min-width: unset;
  min-height: unset;
}
</style>
<style scoped>

.searchbar-input {
  padding-top: unset;
  padding-bottom: unset;
}

ion-content {
  --padding-start: 0;
  --padding-end: 0;
}

ion-searchbar {
  min-width: 200px;
}

ion-col {
  margin: 0;
  padding: 0;
}

ion-card-title {
  font-size: 22px;
}

/*@media (min-width: 350px) {*/

/*}*/

/*!*###############################################################*!*/
/*@media (min-width: 576px) {*/

/*}*/

/*!*###############################################################*!*/
/*@media (min-width: 768px) {*/

/*}*/

/*!*###############################################################*!*/
/*@media (min-width: 992px) {*/

/*}*/

/*!*###############################################################*!*/
/*@media (min-width: 1024px) {*/

/*}*/

/*!*###############################################################*!*/
/*@media (min-width: 1150px) {*/

/*}*/

/*!*###############################################################*!*/
/*@media (min-width: 1281px) {*/

/*}*/
</style>
