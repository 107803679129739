<script setup>
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonItem,
  IonToolbar,
  IonCol,
} from "@ionic/vue";

import { QSpace, QTooltip, QImg } from "quasar";

import { useGlobalStore } from "@/store/global";
import { ref } from "vue";

import { useRoute } from "vue-router";
import { DataStore } from "aws-amplify/datastore";
import {DevFittrLast} from "../models";

const store = useGlobalStore();

const props = defineProps({
  item: Object,
  dbModel: Function,
  setFavorite: Function,
});

const route = useRoute();

//const loadError = (event, filename) => {
//  if (!event?.target?.src?.includes("modelImagesFittr")) {
//    event.target.src =
//      "https://d1mxpim4y30517.cloudfront.net/modelImagesFittr/" + filename;
//  }
//};

const setImageLoadingState = () => {
  store.imageLoadingState = true;
};

const initModalModels = async (id) => {
  store.isOpen = true;

  store.activeModels = [];

  // const model = store.initialisedModels.find(model => model.modelID === id);
  const model = JSON.parse(
    JSON.stringify(
      store.initialisedModels.find((model) => model.modelID === id)
    )
  );
  const sizes = [];
  if (route.path.includes("osb")) {
    const lasttypes = model?.lasttypes;

    const lasts = lasttypes;

    const temp = await DataStore.query(store.Lasts, (c) =>
      c.lasttype.eq(lasts[0])
    );
    for (let i = 0; i < temp.length; i++) {
      if (
        temp[i].shoesize >= model.sizerangeMin &&
        temp[i].shoesize <= model.sizerangeMax
      ) {
        sizes.push(temp[i].shoesize.toString());
      }
    }
    sizes.sort((a, b) => a - b);
    model.sizes = sizes;
  }

  store.activeModel = model;
  store.model = model;

  const last_left = await DataStore.query(DevFittrLast, (c) => c.and(c => [
    c.lasttype.eq(model.lasttype_left),
    c.shoesize.eq(model.size_left),
    c.category.contains(store.category),
    c.brand.eq(model.brand),
  ]));

  const last_right = await DataStore.query(DevFittrLast, (c) => c.and(c => [
    c.lasttype.eq(model.lasttype_right),
    c.shoesize.eq(model.size_right),
    c.category.contains(store.category),
    c.brand.eq(model.brand),
  ]));

  store.lastLeft = last_left[0];
  store.lastRight = last_right[0];

  console.log(last_left)
  console.log(model);

  store.activeModels.push(model);

  console.log(props.dbModel.name);
  let modelFamily;
  if (props.dbModel.name === "DevFootcareModel") {
    //  modelFamily = await DataStore.query(props.dbModel, c => c.modelnumber('eq', model?.modelnumber).colornumber('ne', model?.colornumber));
    modelFamily = await DataStore.query(props.dbModel, (c) =>
      c.and((c) => [
        c.modelnumber.eq(model?.modelnumber),
        c.colornumber.ne(model?.colornumber),
      ])
    );
  } else {
    //  modelFamily = await DataStore.query(props.dbModel, c => c.modelnumber('eq', model?.modelnumber).colornumber('ne', model?.colornumber).brand('eq', model?.brand));
    //  modelFamily = await DataStore.query(props.dbModel, (c) =>
    //     c.modelnumber.eq(model?.modelnumber).colornumber.ne(model?.colornumber).brand.eq(model?.brand));
    modelFamily = await DataStore.query(props.dbModel, (c) =>
      c.and((c) => [
        c.modelnumber.eq(model?.modelnumber),
        c.colornumber.ne(model?.colornumber),
        c.brand.eq(model?.brand),
      ])
    );
    let lasttypes = JSON.stringify(store.activeModel.lasttypes);

    lasttypes = lasttypes
      ?.replaceAll('"', "")
      .replace("[", "")
      .replace("]", "")
      .replaceAll(" ", "")
      .split(",");
    console.log(lasttypes);
    store.lastSizes = [];
    console.log(store.category)
    for (let i = 0; i < lasttypes.length; i++) {
      const lasttype = {};
      lasttype.lasttype = lasttypes[i];
      const temp = await DataStore.query(store.Lasts, (c) =>
        c.and((c) => [
          c.lasttype.eq(lasttypes[i].toString()),
          c.category.contains(store.category)
        ])
      );
      console.log(temp)
      const sizes = [];
      for (let j = 0; j < temp.length; j++) {
        sizes.push(parseFloat(temp[j].shoesize));
      }
      lasttype.sizes = sizes;
      lasttype.sizes.sort((a, b) => a - b);
      store.lastSizes.push(lasttype);
    }
    console.log(store.lastSizes);
  }

  for (let i = 0; i < modelFamily.length; i++) {
    const modelFamilyItem = modelFamily[i];
    let temp = store.initialisedModels.find(
      (model) => model.modelID === modelFamilyItem.id
    );
    if (temp) store.activeModels.push(temp);
  }

  store.isOpenRef = true;
};
</script>

<template>
  <!--	<ion-col-->
  <!--			size-xs="12"-->
  <!--			size-sm="6"-->
  <!--			size-md="6"-->
  <!--			size-lg="4"-->
  <!--			size-xl="4">-->
  <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
    <ion-card button>
      <ion-toolbar style="--background: transparent">
        <ion-buttons style="padding-left: 8px; padding-right: 8px">
          <ion-button
            slot="start"
            shape="round"
            @click="props.setFavorite($event, item?.modelID)"
          >
            <i
              :id="'favo_' + item?.modelID"
              slot="icon-only"
              style="
                opacity: 0.75;
                color: var(--ion-color-secondary);
                font-size: 26px;
              "
              class="fal fa-heart"
            ></i>
          </ion-button>
          <q-space></q-space>
          <ion-button
            slot="end"
            v-if="
              props?.item?.attributes?.specs?.indexOf(
                '6849475f-b7f0-46b5-a9ec-6e5b8f790fe6'
              ) !== -1 && route?.path?.includes('osb')
            "
            shape="round"
          >
            <img
              slot="icon-only"
              alt="loading"
              style="
                border-radius: 75px;
                opacity: 0.75;
                height: 26px;
                width: 26px;
              "
              src="../assets/velcro.png"
            />
            <q-tooltip max-width="50%" style="font-size: 16px">
              Dit model is ook beschikbaar met klittenbandsluiting. U kunt dit aangeven in het bestelformulier indien gewenst.
            </q-tooltip>
          </ion-button>
          <ion-button
            slot="end"
            v-if="
              props?.item?.attributes?.closings?.indexOf(
                '0b535454-074a-45f0-be42-5af1abff277d'
              ) !== -1 && route?.path?.includes('osb')
            "
            shape="round"
          >
            <img
              slot="icon-only"
              alt="loading"
              style="
                border-radius: 75px;
                opacity: 0.75;
                height: 26px;
                width: 26px;
              "
              src="../assets/zipper.png"
            />
            <q-tooltip max-width="50%" style="font-size: 16px">
              Dit model heeft een rits die mogelijk niet goed zichtbaar is op de foto.
            </q-tooltip>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>

      <ion-card-content
        tappable
        style="padding-top: 0"
        @click="initModalModels(props?.item?.modelID)"
      >
        <ion-item
          style="--inner-padding-end: 0; --padding-start: 0; z-index: 0"
        >
          <q-img
            placeholder-src="../assets/img/blank.png"
            style="margin-bottom: 10px"
            :ratio="1"
            no-spinner
            loading="eager"
            fit="contain"
            class="rounded-borders"
            @load="setImageLoadingState()"
            :draggable="false"
            :src="store.imagePath + props?.item?.filename"
            :alt="
              props?.item?.brand +
                ' ' +
                props?.item?.modelnumber +
                ' ' +
                props?.item?.colornumber || ''
            "
          />
          <!--											<template v-slot:loading>-->
          <!--												<ion-spinner>-->
          <!--												</ion-spinner>-->
          <!--											</template>-->
          <!--										</q-img>-->
        </ion-item>
        <!--			</q-intersection>-->

        <ion-card-title
          style="
            font-size: 1.2em;
            color: var(--ion-color-primary);
            padding-left: 10px;
            margin-top: 5px;
          "
        >
          <span
            >Model:
            {{ props?.item?.modelnumber }}
          </span>
        </ion-card-title>

        <ion-card-subtitle style="padding-left: 12px" v-if="props?.item?.colornumber">
          <span
            :style="[
              props?.item?.colornumber !== 'na' ? 'opacity:1' : 'opacity:0',
            ]"
          >
            {{ "Kleur: " + props?.item?.colornumber }}
          </span>
        </ion-card-subtitle>
        <!--                        <ion-card-subtitle style="padding-left: 12px">-->
        <!--                          <span v-if="item.price"> Price:-->
        <!--                            {{ item.price }}-->
        <!--                        </span>-->
        <!--                        </ion-card-subtitle>-->
      </ion-card-content>
    </ion-card>
  </div>
  <!--	</ion-col>-->
</template>

<style scoped>
ion-col {
  margin: 0;
  padding: 0;
}
</style>
