<template>
  <IonPage>
    <ion-header :translucent="true">
      <ion-toolbar :color="store.url.includes('kievit') ? 'light' : 'primary'">
        <ion-buttons slot="start">
          <ion-back-button
            :style="
              store.url.includes('kievit')
                ? 'color:var(--ion-color-dark)'
                : 'color:var(--ion-color-light)'
            "
            default-href="/home"
          ></ion-back-button>
          <!--          <img-->
          <!--            @click="() => ionRouter.navigate('/home', 'back', 'push')"-->
          <!--            class="header-logo"-->
          <!--            alt="no-image"-->
          <!--            src="../assets/logo.png"-->
          <!--          />-->
          <img
            @click="() => (raam.href = raam.origin)"
            class="header-logo"
            alt="no-image"
            :src="store.app_logo"
          />
        </ion-buttons>

        <ion-buttons slot="end">
          <ion-button
            :style="
              store.url.includes('kievit')
                ? 'color:var(--ion-color-dark)'
                : 'color:var(--ion-color-light)'
            "
          >
            <i
              slot="icon-only"
              style="font-size: 24px"
              class="fa-regular fa-question-circle"
            ></i>
          </ion-button>
          <ion-button
            :style="
              store.url.includes('kievit')
                ? 'color:var(--ion-color-dark)'
                : 'color:var(--ion-color-light)'
            "
          >
            <ion-menu-toggle
              style="
                z-index: 200;
                position: absolute;
                width: 100px;
                height: 100%;
              "
            ></ion-menu-toggle>
            <i style="font-size: 24px" class="fa-regular fa-bars"></i>
            <ion-badge
              color="secondary"
              style="
                display: none;
                z-index: 100;
                position: fixed;
                right: 0;
                top: 0;
              "
              :style="
                !store.logged_in_user ? 'display: block' : 'display: none'
              "
            >
              <i class="fa-regular fa-user-xmark"></i
            ></ion-badge>
            <ion-menu-toggle
              style="position: absolute; width: 100%; height: 100%"
            ></ion-menu-toggle>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <IonContent
      :color="store.url.includes('kievit') ? 'white' : 'light'"
      style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 10px;
      "
    >
      <ion-grid>
        <ion-row>
          <!--          <ion-col size-xs="12" size-sm="6" size-md="1" size-lg="1" size-xl="1">
                      <ion-card style="padding: 0; margin-right: 0" class="custom-card">
                        <ion-card-content
                          style="
                            padding-inline-start: 10px;
                            padding-inline-end: 10px;
                            display: flex;
                            flex-direction: column;
                          "
                        >
                          <ion-button
                            @click="rotateModelOnZPlane(1)"
                            shape="round"
                            size="large"
                            style="margin-top: 24px"
                          >
                            <i
                              style="padding: 6px; font-size: 24px"
                              slot="icon-only"
                              class="fa-regular fa-rotate-left"
                            ></i>
                          </ion-button>
                          <ion-button
                            @click="rotateModelOnZPlane(-1)"
                            shape="round"
                            size="large"
                            style="margin-top: 24px"
                          >
                            <i
                              style="padding: 6px; font-size: 24px"
                              slot="icon-only"
                              class="fa-regular fa-rotate-right"
                            ></i>
                          </ion-button>

                          <ion-button
                            @click="moveModelOnZPlane(-1, 0)"
                            shape="round"
                            size="large"
                            style="margin-top: 24px"
                          >
                            <i
                              style="padding: 6px; font-size: 24px"
                              slot="icon-only"
                              class="fa-regular fa-arrow-left"
                            ></i>
                          </ion-button>
                          <ion-button
                            @click="moveModelOnZPlane(1, 0)"
                            shape="round"
                            size="large"
                            style="margin-top: 24px"
                          >
                            <i
                              style="padding: 6px; font-size: 24px"
                              slot="icon-only"
                              class="fa-regular fa-arrow-right"
                            ></i>
                          </ion-button>
                          <ion-button
                            @click="moveModelOnZPlane(0, -1)"
                            shape="round"
                            size="large"
                            style="margin-top: 24px"
                          >
                            <i
                              style="padding: 6px; font-size: 24px"
                              slot="icon-only"
                              class="fa-regular fa-arrow-up"
                            ></i>
                          </ion-button>
                          <ion-button
                            @click="moveModelOnZPlane(0, 1)"
                            shape="round"
                            size="large"
                            style="margin-top: 24px"
                          >
                            <i
                              style="padding: 6px; font-size: 24px"
                              slot="icon-only"
                              class="fa-regular fa-arrow-down"
                            ></i>
                          </ion-button>
                        </ion-card-content>
                      </ion-card>
                    </ion-col>-->

          <ion-col size-xs="12" size-sm="6" size-md="5" size-lg="5" size-xl="5">
            <ion-card style="padding: 0; margin-right: 0" class="custom-card">
              <ion-card-content>
                <!-- Right side: 3D Viewer -->
                <ion-grid>
                  <ion-row>
                    <ion-col size="12">
                      <div
                        style="
                          border: 1px solid var(--ion-color-dark);
                          border-radius: 6px;
                        "
                      >
                        <div style="border-radius: 5px" ref="target"></div>

                        <q-inner-loading :showing="visible">
                          <q-spinner-hourglass size="50px" color="primary" />
                        </q-inner-loading>
                      </div>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size="12" style="padding: 0">
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: space-evenly;
                        "
                      >
                        <ion-button
                          @click="rotateModelOnZPlane(1)"
                          shape="round"
                          size="large"
                          fill="clear"
                        >
                          <i
                            style="font-size: 24px"
                            slot="icon-only"
                            class="fa-regular fa-rotate-left"
                          ></i>
                        </ion-button>
                        <ion-button
                          @click="rotateModelOnZPlane(-1)"
                          shape="round"
                          size="large"
                          fill="clear"
                        >
                          <i
                            style="font-size: 24px"
                            slot="icon-only"
                            class="fa-regular fa-rotate-right"
                          ></i>
                        </ion-button>

                        <ion-button
                          @click="moveModelOnZPlane(-1, 0)"
                          shape="round"
                          size="large"
                          fill="clear"
                        >
                          <i
                            style="font-size: 24px"
                            slot="icon-only"
                            class="fa-regular fa-arrow-left"
                          ></i>
                        </ion-button>
                        <ion-button
                          @click="moveModelOnZPlane(1, 0)"
                          shape="round"
                          size="large"
                          fill="clear"
                        >
                          <i
                            style="font-size: 24px"
                            slot="icon-only"
                            class="fa-regular fa-arrow-right"
                          ></i>
                        </ion-button>
                        <ion-button
                          @click="moveModelOnZPlane(0, -1)"
                          shape="round"
                          size="large"
                          fill="clear"
                        >
                          <i
                            style="font-size: 24px"
                            slot="icon-only"
                            class="fa-regular fa-arrow-up"
                          ></i>
                        </ion-button>
                        <ion-button
                          @click="moveModelOnZPlane(0, 1)"
                          shape="round"
                          size="large"
                          fill="clear"
                        >
                          <i
                            style="font-size: 24px"
                            slot="icon-only"
                            class="fa-regular fa-arrow-down"
                          ></i>
                        </ion-button>
                      </div>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>

              <ion-list v-if="width" style="margin-left: 5%; width: 90%">
                <!-- Right side: 3D Viewer -->
                <ion-item style="--min-height: unset">
                  <div style="width: 30%">Voetlengte:</div>
                  <div style="width: 70%">{{ Math.round(length) }}</div>
                </ion-item>
                <ion-item>
                  <div style="width: 30%">Balomvang:</div>
                  <div style="width: 70%">{{ Math.round(circumference) }}</div>
                </ion-item>
                <ion-item>
                  <div style="width: 30%">Balwijdte:</div>
                  <div style="width: 70%">{{ Math.round(width) }}</div>
                </ion-item>
              </ion-list>
            </ion-card>
          </ion-col>

          <ion-col size-xs="12" size-sm="6" size-md="2" size-lg="2" size-xl="2">
            <ion-card class="custom-card">
              <ion-card-content
                style="
                  padding-inline-start: 10px;
                  padding-inline-end: 10px;
                  display: flex;
                  flex-direction: column;
                "
              >
                <ion-button v-if="files_loaded === false" @click="setOpen(true, 'upload')"
                  >Upload STL
                </ion-button>
                <ion-button v-if="files_loaded === false"
                  @click="setOpen(true, 'library')"
                  style="margin-top: 16px"
                  >Bibliotheek
                </ion-button>
                <ion-button v-if="files_loaded === true" color="danger"
                    style="margin-top: 16px"
                >Remove Scans
                </ion-button>
                <ion-button v-if="files_loaded === true"
                            @click="navigate"
                            style="margin-top: 16px"
                >Verder
                </ion-button>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-modal :is-open="isOpen">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button @click="setOpen(false, null)">Cancel</ion-button>
            </ion-buttons>
            <ion-buttons slot="end">
              <ion-button @click="saveScans" :strong="true">Confirm</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content v-if="contentType === 'upload'" class="ion-padding">
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;
            "
          >
            <q-input
              label="Klantnummer/geboortedatum"
              v-model="clientIdOne"
              ref="inputRef"
              type="text"
              name="clientIdOne"
              style="width: 40%"
            />
            <q-input
              label="Eerste letters achternaam"
              v-model="clientIdTwo"
              ref="inputRef"
              type="text"
              name="clientIdTwo"
              style="width: 40%"
            />
          </div>
          <ion-toolbar style="margin-top: 16px; text-align: center">
            <ion-title>STL Files</ion-title>
          </ion-toolbar>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;
            "
          >
            <q-uploader
              @added="onAdded($event, 'left')"
              hide-upload-btn
              label="Links"
              style="width: 40%"
            />
            <q-uploader
              @added="onAdded($event, 'right')"
              hide-upload-btn
              label="Rechts"
              url="http://localhost:4444/upload"
              style="width: 40%"
            />
          </div>
        </ion-content>
        <ion-content v-if="contentType === 'library'" class="ion-padding">
          <q-list bordered padding class="rounded-borders">
            <template v-for="item in library" key="item.id">
              <q-item @click="loadScan(item.id)" clickable v-ripple>
                <q-item-section avatar top>
                  <q-avatar color="grey" text-color="white">
                    <i style="font-size: 22px" class="fal fa-tablet"></i>
                  </q-avatar>
                </q-item-section>

                <q-item-section>
                  <q-item-label lines="1"
                    >{{ item.client_id_two + " " + item.client_id_one }}
                  </q-item-label>
                  <q-item-label caption
                    >{{ normalizeDate(item.date) }}
                  </q-item-label>
                  <q-item-label>Files:</q-item-label>
                  <q-item-label style="margin-left: 16px" caption
                    >{{ item.filename_left }}
                  </q-item-label>
                  <q-item-label style="margin-left: 16px" caption
                    >{{ item.filename_right }}
                  </q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-icon name="info" />
                </q-item-section>
              </q-item>
              <q-separator spaced />
            </template>
          </q-list>
        </ion-content>
      </ion-modal>
    </IonContent>
  </IonPage>
</template>

<script setup>
import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonList,
  IonMenuToggle,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { DataStore, Predicates, SortDirection } from "aws-amplify/datastore";
import { downloadData, uploadData } from "aws-amplify/storage";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { onBeforeUnmount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import outline from "../assets/outline_L.png";
import { DevFittr3DScans } from "../models";
import { useGlobalStore } from "../store/global";

const store = useGlobalStore();

const router = useRouter();
const visible = ref(false);
let rotationTimeout; // Timeout to track when rotation is done

const isOpen = ref(false);
const contentType = ref("");
const library = ref();

const setOpen = async (open, content) => {
  if (content === "upload") {
    contentType.value = "upload";
  } else if (content === "library") {
    library.value = await DataStore.query(DevFittr3DScans, Predicates.ALL, {
      sort: (s) => s.date(SortDirection.ASCENDING),
    });
    contentType.value = "library";
  }
  isOpen.value = open;
};

const clientIdOne = ref("");
const clientIdTwo = ref("");
const filename_left = ref("");
const filename_right = ref("");
const file_left = ref();
const file_right = ref();

function normalizeDate(date) {
  const toNormalize = new Date(date);
  return (
    (toNormalize.getDate() > 9
      ? toNormalize.getDate()
      : "0" + toNormalize.getDate()) +
    "-" +
    (toNormalize.getMonth() > 8
      ? toNormalize.getMonth() + 1
      : "0" + (toNormalize.getMonth() + 1)) +
    "-" +
    toNormalize.getFullYear() +
    " " +
    (toNormalize.getHours() > 9
      ? toNormalize.getHours()
      : "0" + toNormalize.getHours()) +
    ":" +
    (toNormalize.getMinutes() > 9
      ? toNormalize.getMinutes()
      : "0" + toNormalize.getMinutes())
  );
}

async function saveScans() {
  const date = Date.now();
  const result = await DataStore.save(
    new DevFittr3DScans({
      client_id_one: clientIdOne.value,
      client_id_two: clientIdTwo.value,
      user: store.logged_in_user.email,
      gender: store.category,
      filename_left: filename_left.value,
      filename_right: filename_right.value,
      organisation: store.logged_in_user["custom:organisation_id"],
      date: date,
    })
  );
  loadSTL(file_left.value[0]);
  await setOpen(false);
}

async function loadScan(id) {
  const scan = await DataStore.query(DevFittr3DScans, id);
  const file_left = await downloadScan(scan.filename_left);
  loadSTL(file_left.body);
  await setOpen(false);
}

async function downloadScan(filename) {
  return await downloadData({
    path: "3DScans/" + filename,
    options: {
      onProgress: (event) => {
        console.log(event.transferredBytes);
      },
    },
  }).result;
}

async function onAdded(files, side) {
  if (side === "left") {
    filename_left.value = files[0].name;
    file_left.value = files;
  } else if (side === "right") {
    filename_right.value = files[0].name;
    file_right.value = files;
  }
  const reader = new FileReader();
  const objectUrl = URL.createObjectURL(files[0]);
  reader.onload = reader.onload = (e) => {
    URL.revokeObjectURL(objectUrl);
    try {
      const result = uploadData({
        path: "3DScans/" + files[0].name,
        // Alternatively, path: ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
        data: files[0],
        options: {
          onProgress: ({ transferredBytes, totalBytes }) => {
            if (totalBytes) {
              console.log(
                `Upload progress ${Math.round(
                  (transferredBytes / totalBytes) * 100
                )} %`
              );
            }
          },
        },
      }).result;
      console.log("Path from Response: ", result);
    } catch (error) {
      console.log("Error : ", error);
    }
  };
  reader.readAsArrayBuffer(files[0]);
}

const rotateModelOnZPlane = (angleInDegrees) => {
  if (!footModel) {
    console.warn("No foot model loaded.");
    return;
  }
  // Remove spheres and measurements during movement
  removeSpheresAndMeasurements();
  const angleInRadians = THREE.MathUtils.degToRad(angleInDegrees); // Convert degrees to radians
  // Apply rotation around the Z-axis
  footModel.rotateZ(angleInRadians);
  //console.log(`Model rotated by ${angleInDegrees} degrees on the Z plane.`);
  // Debounce recalculating measurements
  if (rotationTimeout) clearTimeout(rotationTimeout); // Clear any existing timeout
  rotationTimeout = setTimeout(() => {
    //console.log("Recalculating measurements...");
    measureWidthAndCircumference(); // Recalculate width and redraw spheres
  }, 1000); // Wait 300ms after the last button press
};

const moveModelOnZPlane = (xOffset, zOffset) => {
  if (!footModel) {
    console.warn("No foot model loaded.");
    return;
  }
  // Remove spheres and measurements during movement
  removeSpheresAndMeasurements();
  // Translate the model
  footModel.position.x += xOffset;
  footModel.position.z += zOffset;
  //console.log(`Model moved by X: ${xOffset}, Z: ${zOffset}`);
  if (rotationTimeout) clearTimeout(rotationTimeout); // Clear any existing timeout
  rotationTimeout = setTimeout(() => {
    //console.log("Recalculating measurements...");
    measureWidthAndCircumference(); // Recalculate width and redraw spheres
  }, 1000);
};

const loadFootOutline = () => {
  const textureLoader = new THREE.TextureLoader();
  textureLoader.load(outline, (texture) => {
    const planeGeometry = new THREE.PlaneGeometry(150, 300); // Adjust size to match your outline proportions
    const planeMaterial = new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true, // Allow for PNG transparency
    });
    const footOutline = new THREE.Mesh(planeGeometry, planeMaterial);

    // Position the outline on the grid
    footOutline.rotation.x = -Math.PI / 2; // Make the outline lie flat
    footOutline.position.set(0, 0.1, 0); // Slightly above the grid for visibility

    // Add to the scene
    scene.add(footOutline);
  });
};

const target = ref(null);

const scene = new THREE.Scene();
scene.background = new THREE.Color(0xffffff);

const camera = new THREE.PerspectiveCamera(
  50,
  window.innerWidth / window.innerHeight,
  0.1,
  5000
);
const renderer = new THREE.WebGLRenderer({ antialias: true });
renderer.shadowMap.enabled = true;

const resizeRendererToFixedAspectRatio = () => {
  const container = target.value; // Target the container element
  if (!container) return;

  const containerWidth = container.clientWidth;
  const containerHeight = containerWidth; // Ensure height is always twice the width

  renderer.setSize(containerWidth, containerHeight); // Match renderer size to fixed aspect ratio
  camera.aspect = containerWidth / containerHeight; // Update the camera's aspect ratio
  camera.updateProjectionMatrix(); // Apply the changes to the camera

  // Adjust the container height to match the calculated size
  container.style.height = `${containerHeight}px`;
};

const ambientLight = new THREE.AmbientLight(0xffffff, 1.2); // Slightly lower ambient light for contrast
scene.add(ambientLight);

// First directional light (main light)
const directionalLight1 = new THREE.DirectionalLight(0xffffff, 2);
directionalLight1.position.set(50, 50, 100);
directionalLight1.castShadow = true;
scene.add(directionalLight1);

// Second directional light (fill light from the opposite side)
const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1.5);
directionalLight2.position.set(-50, 50, -100); // Opposite side but angled a bit differently
directionalLight2.castShadow = true;
scene.add(directionalLight2);

// Third directional light (slightly from above)
const directionalLight3 = new THREE.DirectionalLight(0xffffff, 1);
directionalLight3.position.set(0, 100, 0); // Positioned above to add overall illumination
directionalLight3.castShadow = true;
scene.add(directionalLight3);

// Add helper grid to the scene
const gridHelper = new THREE.GridHelper(900, 60);
gridHelper.position.set(0, 0, -200); // Shift the grid downward

scene.add(gridHelper);

let controls;
let footModel = null;

const raam = window.location;

const width = ref();
const circumference = ref();
const length = ref();

const measureWidthAndCircumference = () => {
  if (!footModel) {
    console.warn("No foot model loaded.");
    return { width: 0, circumference: 0 };
  }

  const geometry = footModel.geometry;
  const positionAttribute = geometry.attributes.position;

  // Precompute world-space vertices
  const worldVertices = [];
  const modelMatrix = footModel.matrixWorld;

  for (let i = 0; i < positionAttribute.count; i++) {
    const localVertex = new THREE.Vector3(
      positionAttribute.getX(i),
      positionAttribute.getY(i),
      positionAttribute.getZ(i)
    );
    worldVertices.push(localVertex.applyMatrix4(modelMatrix));
  }

  // Horizontal line coordinates
  const horizontalLineStart = { x: -100, z: -25 };
  const horizontalLineEnd = { x: 100, z: -63 };
  const inwardStep = 1.0; // Step size for moving inward
  const heightStep = 1.0; // Step size along the Y-axis
  const maxHeight = 50;
  const startHeight = 0;
  const tolerance = 1.0;

  let widestLeftPoint = null;
  let widestRightPoint = null;

  const interpolateZ = (x) => {
    const factor =
      (x - horizontalLineStart.x) /
      (horizontalLineEnd.x - horizontalLineStart.x);
    return (
      horizontalLineStart.z +
      factor * (horizontalLineEnd.z - horizontalLineStart.z)
    );
  };

  const findVertex = (x, z) => {
    for (let y = startHeight; y <= maxHeight; y += heightStep) {
      for (const worldVertex of worldVertices) {
        if (
          Math.abs(worldVertex.x - x) <= tolerance &&
          Math.abs(worldVertex.z - z) <= tolerance &&
          Math.abs(worldVertex.y - y) <= tolerance
        ) {
          return worldVertex;
        }
      }
    }
    return null;
  };

  let leftFactor = 0;
  let rightFactor = 1;

  while (leftFactor < rightFactor) {
    const leftX =
      horizontalLineStart.x +
      leftFactor * (horizontalLineEnd.x - horizontalLineStart.x);
    const leftZ = interpolateZ(leftX);
    const rightX =
      horizontalLineStart.x +
      rightFactor * (horizontalLineEnd.x - horizontalLineStart.x);
    const rightZ = interpolateZ(rightX);

    if (!widestLeftPoint) {
      widestLeftPoint = findVertex(leftX, leftZ);
    }

    if (!widestRightPoint) {
      widestRightPoint = findVertex(rightX, rightZ);
    }

    if (widestLeftPoint && widestRightPoint) {
      break;
    }

    if (!widestLeftPoint) leftFactor += inwardStep / 200;
    if (!widestRightPoint) rightFactor -= inwardStep / 200;
  }

  if (!widestLeftPoint || !widestRightPoint) {
    console.warn("Unable to find widest points.");
    return { width: 0, circumference: 0 };
  }

  if (widestLeftPoint && widestRightPoint) {
    drawSpheresBetweenWidthPoints(widestLeftPoint, widestRightPoint, 1);
  }

  const measureLineLength = (markers) => {
    if (markers.length < 2) {
      console.warn("Not enough markers to measure a line.");
      return 0;
    }

    let length = 0;
    for (let i = 0; i < markers.length - 1; i++) {
      const startPoint = markers[i];
      const endPoint = markers[i + 1];
      length += startPoint.distanceTo(endPoint);
    }

    return length;
  };

  const measureCircumference = (
    leftPoint,
    rightPoint,
    stepSize = 2,
    yStep = 2,
    maxDistance = 150
  ) => {
    if (!leftPoint || !rightPoint) {
      console.warn("Width measuring points are not defined.");
      return 0;
    }

    const modelMatrix = footModel.matrixWorld; // Get the transformation matrix of the model
    const geometry = footModel.geometry;
    const positionAttribute = geometry.attributes.position;

    // Precompute world-space vertices
    const worldVertices = [];
    for (let i = 0; i < positionAttribute.count; i++) {
      const localVertex = new THREE.Vector3(
        positionAttribute.getX(i),
        positionAttribute.getY(i),
        positionAttribute.getZ(i)
      );
      worldVertices.push(localVertex.applyMatrix4(modelMatrix));
    }

    const tolerance = 1.0; // Tolerance for matching vertices

    // Function to find a marker on the model surface along the Y-axis
    const findMarkerOnModelSurface = (startPoint, direction) => {
      for (let offset = 0; offset <= maxDistance; offset += yStep) {
        const testY = startPoint.y + direction * offset;

        for (const vertex of worldVertices) {
          if (
            Math.abs(vertex.x - startPoint.x) <= tolerance &&
            Math.abs(vertex.z - startPoint.z) <= tolerance &&
            Math.abs(vertex.y - testY) <= tolerance
          ) {
            // Return the first matching point on the model surface
            return new THREE.Vector3(vertex.x, vertex.y, vertex.z);
          }
        }
      }
      return null;
    };

    // Draw spheres between left and right points
    const distance = leftPoint.distanceTo(rightPoint);
    const stepCount = Math.floor(distance / stepSize);

    const topMarkers = [];
    const bottomMarkers = [];

    for (let i = 0; i <= stepCount; i++) {
      const t = i / stepCount; // Interpolation factor (0 to 1)
      const startPoint = new THREE.Vector3().lerpVectors(
        leftPoint,
        rightPoint,
        t
      ); // Interpolated position

      // Find markers above and below the start point
      const markerAbove = findMarkerOnModelSurface(startPoint, 1); // Check upward
      const markerBelow = findMarkerOnModelSurface(startPoint, -1); // Check downward

      if (markerAbove) {
        topMarkers.push(markerAbove);
        // Place a green sphere for the top marker
        const sphereAbove = new THREE.Mesh(
          new THREE.SphereGeometry(1, 8, 8),
          new THREE.MeshBasicMaterial({ color: 0x00ff00 }) // Green sphere
        );
        sphereAbove.position.copy(markerAbove);
        scene.add(sphereAbove);
      }

      if (markerBelow) {
        bottomMarkers.push(markerBelow);
        // Place a blue sphere for the bottom marker
        const sphereBelow = new THREE.Mesh(
          new THREE.SphereGeometry(1, 8, 8),
          new THREE.MeshBasicMaterial({ color: 0x0000ff }) // Blue sphere
        );
        sphereBelow.position.copy(markerBelow);
        scene.add(sphereBelow);
      }
    }

    // Connect and measure top markers (green)
    const topLineLength = measureLineLength(topMarkers);
    //console.log(`Top line length: ${topLineLength.toFixed(2)} mm`);

    // Connect and measure bottom markers (blue)
    const bottomLineLength = measureLineLength(bottomMarkers);
    //console.log(`Bottom line length: ${bottomLineLength.toFixed(2)} mm`);

    // Calculate total circumference
    circumference.value = topLineLength + bottomLineLength;
    //console.log(`Total circumference: ${circumference.toFixed(2)} mm`);

    return circumference.value;
  };

  measureCircumference(widestLeftPoint, widestRightPoint);
  //console.log(`Measured circumference: ${circumference.value.toFixed(2)} mm`);

  // Draw spheres for the width points
  const drawSphere = (position, color) => {
    const sphere = new THREE.Mesh(
      new THREE.SphereGeometry(1, 8, 8),
      new THREE.MeshBasicMaterial({ color })
    );
    sphere.position.copy(position);
    scene.add(sphere);
  };

  drawSphere(widestLeftPoint, 0xff0000);
  drawSphere(widestRightPoint, 0x0000ff);

  // Calculate width
  width.value = widestLeftPoint.distanceTo(widestRightPoint);
  //console.log(`Width: ${width.value.toFixed(2)} mm`);

  /* setTimeout(() => {
   visible.value = false;
   }, 1000); */
};

const drawSpheresBetweenWidthPoints = (leftPoint, rightPoint, stepSize = 2) => {
  if (!leftPoint || !rightPoint) {
    console.warn("Width measuring points are not defined.");
    return;
  }

  const distance = leftPoint.distanceTo(rightPoint); // Total distance between points
  const stepCount = Math.floor(distance / stepSize); // Number of spheres to draw

  for (let i = 0; i <= stepCount; i++) {
    const t = i / stepCount; // Interpolation factor (0 to 1)
    const position = new THREE.Vector3().lerpVectors(leftPoint, rightPoint, t); // Interpolated position

    // Create and place a sphere at the calculated position
    const sphere = new THREE.Mesh(
      new THREE.SphereGeometry(1, 8, 8),
      new THREE.MeshBasicMaterial({ color: 0xffff00 }) // Yellow spheres
    );
    sphere.position.copy(position);
    scene.add(sphere);
  }

  //console.log(`Placed ${stepCount + 1} spheres between width points.`);
};

const measureModelLengthWithLines = () => {
  if (!footModel) {
    console.warn("No foot model loaded.");
    return 0;
  }

  const geometry = footModel.geometry;
  const positionAttribute = geometry.attributes.position;

  // Precompute world-space vertices
  const worldVertices = [];
  const modelMatrix = footModel.matrixWorld;

  for (let i = 0; i < positionAttribute.count; i++) {
    const localVertex = new THREE.Vector3(
      positionAttribute.getX(i),
      positionAttribute.getY(i),
      positionAttribute.getZ(i)
    );
    worldVertices.push(localVertex.applyMatrix4(modelMatrix)); // Transform to world space
  }

  // Determine bounds for initializing the lines
  let minZ = Infinity;
  let maxZ = -Infinity;

  for (const vertex of worldVertices) {
    if (vertex.z < minZ) minZ = vertex.z;
    if (vertex.z > maxZ) maxZ = vertex.z;
  }

  const tolerance = 1.0; // Tolerance for detecting vertices near the lines
  const stepSize = 1.0; // Step size for moving the lines
  const lineWidth = 200; // Line width, spanning the entire model width

  let forwardLineZ = maxZ + 10; // Start slightly ahead of the model
  let backwardLineZ = minZ - 10; // Start slightly behind the model
  let forwardContactPoint = null;
  let backwardContactPoint = null;

  // Helper function to draw lines
  const drawLine = (z, color) => {
    const start = new THREE.Vector3(-lineWidth / 2, 0, z);
    const end = new THREE.Vector3(lineWidth / 2, 0, z);
    const lineGeometry = new THREE.BufferGeometry().setFromPoints([start, end]);
    const lineMaterial = new THREE.LineBasicMaterial({ color });
    const line = new THREE.Line(lineGeometry, lineMaterial);
    scene.add(line);
    console.log(`Line drawn at Z=${z}`);
  };

  // Helper function to check vertices along a line
  const findLineContact = (z) => {
    let farthestTop = null;
    let farthestBottom = null;

    for (const vertex of worldVertices) {
      if (Math.abs(vertex.z - z) <= tolerance) {
        if (!farthestTop || vertex.y > farthestTop.y) farthestTop = vertex;
        if (!farthestBottom || vertex.y < farthestBottom.y)
          farthestBottom = vertex;
      }
    }

    return { farthestTop, farthestBottom };
  };

  // Move the forward line backward until contact
  while (true) {
    //drawLine(forwardLineZ, 0xff0000); // Red for forward line
    const { farthestTop, farthestBottom } = findLineContact(forwardLineZ);
    if (farthestTop && farthestBottom) {
      forwardContactPoint = { top: farthestTop, bottom: farthestBottom };
      break;
    }
    forwardLineZ -= stepSize; // Move backward
  }

  // Move the backward line forward until contact
  while (true) {
    //drawLine(backwardLineZ, 0x0000ff); // Blue for backward line
    const { farthestTop, farthestBottom } = findLineContact(backwardLineZ);
    if (farthestTop && farthestBottom) {
      backwardContactPoint = { top: farthestTop, bottom: farthestBottom };
      break;
    }
    backwardLineZ += stepSize; // Move forward
  }

  if (!forwardContactPoint || !backwardContactPoint) {
    console.warn("Unable to find model extremes.");
    return 0;
  }

  // Helper function to draw spheres
  const createSphere = (position, color) => {
    const sphereGeometry = new THREE.SphereGeometry(1, 8, 8);
    const sphereMaterial = new THREE.MeshBasicMaterial({ color });
    const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
    sphere.position.copy(position);
    scene.add(sphere);
    console.log(
      `Sphere placed at: ${position.x}, ${position.y}, ${position.z}`
    );
  };

  // Place spheres at the contact points
  createSphere(forwardContactPoint.top, 0xff0000); // Red for forward top
  createSphere(forwardContactPoint.bottom, 0xff0000); // Red for forward bottom
  createSphere(backwardContactPoint.top, 0x0000ff); // Blue for backward top
  createSphere(backwardContactPoint.bottom, 0x0000ff); // Blue for backward bottom

  // Measure the length as the horizontal distance between the forward and backward lines
  length.value = forwardLineZ - backwardLineZ;
  console.log(`Model Length (Z-axis): ${length.value.toFixed(2)} mm`);
};

const files_loaded = ref(false);
// Function to load STL File and align it with the grid
const loadSTL = (files) => {
  const file = files;
  if (!file) return;

  const reader = new FileReader();
  reader.onload = (e) => {
    const contents = e.target.result;
    const loader = new STLLoader();
    const geometry = loader.parse(contents);
    const material = new THREE.MeshPhongMaterial({
      color: 0x909090,
      shininess: 30,
    });
    footModel = new THREE.Mesh(geometry, material);
    footModel.scale.set(1, 1, 1);
    footModel.castShadow = true;
    footModel.receiveShadow = true;

    // Align model with the grid (place the flat surface on the grid)
    alignFootToGrid(footModel);

    scene.add(footModel);
    files_loaded.value = true;
    measureWidthAndCircumference();
    measureModelLengthWithLines();
    //console.log("Length of the model:", length, "mm");
    //console.log(`Measured width: ${width.width.toFixed(2)} mm`);
  };
  reader.readAsArrayBuffer(file);
};

// Function to align the foot model's sole with the grid
const alignFootToGrid = (footModel) => {
  // Compute the bounding box of the model
  footModel.geometry.computeBoundingBox();
  const boundingBox = footModel.geometry.boundingBox;

  // Step 1: Rotate the foot to make the sole face downward
  // Rotate 90 degrees on the X-axis to stand the foot upright and then flip it 180 degrees to make the sole face down
  const upRotation = new THREE.Matrix4().makeRotationX(Math.PI / 2); // Rotate to stand the foot upright
  footModel.applyMatrix4(upRotation);

  const flipRotation = new THREE.Matrix4().makeRotationX(Math.PI); // Flip 180 degrees to correct orientation
  footModel.applyMatrix4(flipRotation);

  // Recompute the bounding box after rotation
  footModel.geometry.computeBoundingBox();
  const newBoundingBox = footModel.geometry.boundingBox;

  // Step 2: Move the model up to align the lowest point of the footsole with the grid
  const yOffset = newBoundingBox.min.y;
  //footModel.position.y -= yOffset; // Move it upward to sit on the grid
  footModel.position.y -= yOffset; // Move it upward to sit on the grid
  footModel.position.z = 125; // Move it upward to sit on the grid

  // Ensure geometry is updated properly
  footModel.geometry.computeBoundingBox();
  footModel.geometry.computeVertexNormals();
  footModel.updateMatrixWorld(true);
};

let isDraggingModel = false; // Track if the model is being dragged
const mouse = new THREE.Vector2(); // For mouse tracking
const raycaster = new THREE.Raycaster(); // For detecting intersections
let dragStartPosition = new THREE.Vector3(); // Store the drag start position

// Common function to handle model dragging
const startDraggingModel = (clientX, clientY) => {
  const rect = renderer.domElement.getBoundingClientRect();
  mouse.x = ((clientX - rect.left) / rect.width) * 2 - 1;
  mouse.y = -((clientY - rect.top) / rect.height) * 2 + 1;

  raycaster.setFromCamera(mouse, camera);

  if (footModel) {
    const intersects = raycaster.intersectObject(footModel);
    if (intersects.length > 0) {
      isDraggingModel = true; // Start dragging
      controls.enabled = false; // Disable OrbitControls while dragging
      dragStartPosition.copy(intersects[0].point); // Save the intersection point
    }
  }
};

const removeSpheresAndMeasurements = () => {
  circumference.value = null;
  width.value = null;
  //Clear previous measurement spheres
  scene.children.slice().forEach((child) => {
    if (child.geometry && child.geometry.type === "SphereGeometry") {
      scene.remove(child);
    }
  });
};

const dragModel = (clientX, clientY) => {
  if (!isDraggingModel || !footModel) return;
  removeSpheresAndMeasurements();
  const rect = renderer.domElement.getBoundingClientRect();
  mouse.x = ((clientX - rect.left) / rect.width) * 2 - 1;
  mouse.y = -((clientY - rect.top) / rect.height) * 2 + 1;

  raycaster.setFromCamera(mouse, camera);

  const plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0); // Plane parallel to the grid
  const intersectPoint = new THREE.Vector3();
  raycaster.ray.intersectPlane(plane, intersectPoint); // Get the intersection point on the plane

  // Update the model position based on drag
  footModel.position.set(
    footModel.position.x + (intersectPoint.x - dragStartPosition.x),
    footModel.position.y,
    footModel.position.z + (intersectPoint.z - dragStartPosition.z)
  );

  dragStartPosition.copy(intersectPoint); // Update drag start position
};

const stopDraggingModel = () => {
  if (isDraggingModel) {
    isDraggingModel = false; // Stop dragging
    controls.enabled = true; // Re-enable OrbitControls

    //Recalculate width
    measureWidthAndCircumference();
    measureModelLengthWithLines();
  }
};

// Add event listeners for both mouse and touch events
const onMouseDownForModel = (event) =>
  startDraggingModel(event.clientX, event.clientY);
const onMouseMoveForModel = (event) => dragModel(event.clientX, event.clientY);
const onMouseUpForModel = stopDraggingModel;

const onTouchStartForModel = (event) => {
  if (event.touches.length === 1) {
    startDraggingModel(event.touches[0].clientX, event.touches[0].clientY);
  }
};
const onTouchMoveForModel = (event) => {
  if (event.touches.length === 1) {
    dragModel(event.touches[0].clientX, event.touches[0].clientY);
  }
};
const onTouchEndForModel = stopDraggingModel;

const drawHorizontalLine = () => {
  // Define the start and end points of the line (left to right)
  const lineStart = new THREE.Vector3(-100, 0.1, 105); // Left of the grid
  const lineEnd = new THREE.Vector3(100, 0.1, 63); // Right of the grid

  // Create the geometry and material for the line
  const lineGeometry = new THREE.BufferGeometry().setFromPoints([
    lineStart,
    lineEnd,
  ]);
  const lineMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 }); // Red color for the line

  // Create the line object
  const horizontalLine = new THREE.Line(lineGeometry, lineMaterial);

  // Position the line to align with the outline image
  horizontalLine.position.z = -130; // Adjust to match the outline's position
  scene.add(horizontalLine);
};

const drawVerticalLine = () => {
  // Define the start and end points of the vertical line (top to bottom)
  const lineStart = new THREE.Vector3(0, 0.1, -500); // Top of the grid
  const lineEnd = new THREE.Vector3(0, 0.1, 500); // Bottom of the grid

  // Create the geometry and material for the line
  const lineGeometry = new THREE.BufferGeometry().setFromPoints([
    lineStart,
    lineEnd,
  ]);
  const lineMaterial = new THREE.LineBasicMaterial({ color: 0x0000ff }); // Blue color for the line

  // Create the line object
  const verticalLine = new THREE.Line(lineGeometry, lineMaterial);

  // Position the line to align with the outline image
  verticalLine.position.x = 0; // Adjust to match the center of the outline
  scene.add(verticalLine);
};

// Setup scene and renderer on mount
onMounted(() => {
  if (target.value) {
    target.value.appendChild(renderer.domElement); // Append renderer to container
    resizeRendererToFixedAspectRatio(); // Set renderer size to fixed aspect ratio

    // Add a resize observer to adjust size when container changes
    const resizeObserver = new ResizeObserver(resizeRendererToFixedAspectRatio);
    resizeObserver.observe(target.value);

    // Cleanup observer on component unmount
    onBeforeUnmount(() => resizeObserver.disconnect());

    // Initialize OrbitControls
    controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;

    // Position the camera directly above the grid
    camera.position.set(0, 330, 0); // Set the camera above the grid
    camera.up.set(0, 0, -1); // Adjust the "up" direction for top-down viewing

    // Adjust the camera's look-at target to shift the grid downward
    camera.lookAt(0, 0, 0); // Look slightly below the grid center (shift downward)

    // Disable rotation for OrbitControls to keep the top-down view
    //controls.enableRotate = false;

    // Add event listeners for mouse and touch events
    window.addEventListener("mousedown", onMouseDownForModel);
    window.addEventListener("mousemove", onMouseMoveForModel);
    window.addEventListener("mouseup", onMouseUpForModel);

    window.addEventListener("touchstart", onTouchStartForModel);
    window.addEventListener("touchmove", onTouchMoveForModel);
    window.addEventListener("touchend", onTouchEndForModel);

    //addAxisLabels();
    animate();
    loadFootOutline();
    drawHorizontalLine(); // Add the horizontal line
    drawVerticalLine(); // Add the vertical line
  }
});

// Remove event listeners before component unmount
onBeforeUnmount(() => {
  window.removeEventListener("mousedown", onMouseDownForModel);
  window.removeEventListener("mousemove", onMouseMoveForModel);
  window.removeEventListener("mouseup", onMouseUpForModel);

  window.removeEventListener("touchstart", onTouchStartForModel);
  window.removeEventListener("touchmove", onTouchMoveForModel);
  window.removeEventListener("touchend", onTouchEndForModel);
});

// Animation loop
const animate = () => {
  requestAnimationFrame(animate);
  controls.update();
  renderer.render(scene, camera);
};

async function navigate() {
  store.scanLengthLeft = Math.round(length.value);
  store.scanWidthLeft = Math.round(width.value);
  store.scanCircumfenceLeft = Math.round(circumference.value);
  await router.push("/osb/foot-dimensions/m");
}
</script>

<style scoped>
canvas {
  border-radius: 15px !important;
}

#target {
  width: 65%;
  height: 600px;
  position: absolute;
  top: 0;
  right: 2.5%;
}

.file-input {
  display: none; /* Hides the actual file input */
}

.material-button {
  background-color: #6200ea; /* Material Design purple */
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  display: inline-block; /* Ensures label behaves like a button */
}

.material-button:hover {
  background-color: #3700b3; /* Darker purple */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2), 0px 6px 12px rgba(0, 0, 0, 0.26);
}

.material-button:active {
  background-color: #1a00a0; /* Even darker purple */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 2px 4px rgba(0, 0, 0, 0.18);
}

.material-button:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px rgba(98, 0, 234, 0.4);
}
</style>
